import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/builds/r3013/public-website-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/builds/r3013/public-website-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/builds/r3013/public-website-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/builds/r3013/public-website-front/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/builds/r3013/public-website-front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/builds/r3013/public-website-front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import restore_state_client_Avkf9UKa4FqvXNYdAVbcnnVMLrsnZ1K_mmTT61bXxXc from "/builds/r3013/public-website-front/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_immediate_client_t556saLvNhyt_A6Pte2fzMGyWdkyhfaA5Fv_426OOe0 from "/builds/r3013/public-website-front/node_modules/nuxt/dist/app/plugins/chunk-reload-immediate.client.js";
import plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms from "/builds/r3013/public-website-front/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/r3013/public-website-front/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/builds/r3013/public-website-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/builds/r3013/public-website-front/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/builds/r3013/public-website-front/.nuxt/sentry-client-config.mjs";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/builds/r3013/public-website-front/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_YOxzu2Rgn246XMgfphZEXZjtzJTMGWdPuDf_KLI_O_0 from "/builds/r3013/public-website-front/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA from "/builds/r3013/public-website-front/plugins/gtm.client.ts";
import sourcebuster_client_51NxlNyN0zmmqHaqyJGEzlA8IyU515Ve7rSPPmcIuCg from "/builds/r3013/public-website-front/plugins/sourcebuster.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  restore_state_client_Avkf9UKa4FqvXNYdAVbcnnVMLrsnZ1K_mmTT61bXxXc,
  chunk_reload_immediate_client_t556saLvNhyt_A6Pte2fzMGyWdkyhfaA5Fv_426OOe0,
  plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_YOxzu2Rgn246XMgfphZEXZjtzJTMGWdPuDf_KLI_O_0,
  gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA,
  sourcebuster_client_51NxlNyN0zmmqHaqyJGEzlA8IyU515Ve7rSPPmcIuCg
]