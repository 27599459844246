export function useLogger(name: string) {
  const success = (...args) => console.log(`%c⭐ Rang [${name}]`, 'background: rgba(0,150,0,0.7); color: white; border-radius: 3px; padding: 0px 10px; margin-right: 10px;', ...args)
  const warn = (...args) => console.log(`%c⭐ Rang [${name}]`, 'background: rgba(0,150,150,0.7); color: white; border-radius: 3px; padding: 0px 10px; margin-right: 10px;', ...args)
  const danger = (...args) => console.log(`%c⭐ Rang [${name}]`, 'background: rgba(0,0,150,0.7); color: white; border-radius: 3px; padding: 0px 10px; margin-right: 10px;', ...args)
  const info = (...args) => console.log(`%c⭐ Rang [${name}]`, 'background: rgba(0,0,0,0.3); color: white; border-radius: 3px; padding: 0px 10px; margin-right: 10px;', ...args)

  return {
    success,
    warn,
    danger,
    info,
  }
}
