import validate from "/builds/r3013/public-website-front/node_modules/nuxt/dist/pages/runtime/validate.js";
import lowercase_45path_45global from "/builds/r3013/public-website-front/middleware/lowercase-path.global.ts";
import redirect_45trailing_45slash_45global from "/builds/r3013/public-website-front/middleware/redirect-trailing-slash.global.js";
import router_45global from "/builds/r3013/public-website-front/middleware/router.global.ts";
export const globalMiddleware = [
  validate,
  lowercase_45path_45global,
  redirect_45trailing_45slash_45global,
  router_45global
]
export const namedMiddleware = {}