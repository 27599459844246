<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
  default: {
    type: Function,
    default: () => {},
  },
})
// Set status code 404
const event = useRequestEvent()
const error = useError()
if (event)
  setResponseStatus(event, 404)

useHead({ title: '404 — Страница не найдена' })

// FFEB3B active
// ECECEC disabled

const activeColor = ref('#FFEB3B')
const disabledColor = ref('#ECECEC')
const pathColors = ref([
  activeColor.value,
  activeColor.value,
  activeColor.value,
  activeColor.value,
  activeColor.value,
])
const handleError = () => clearError({ redirect: '/' })
const stackArr = ref([])

function toggleColors() {
  const tempColor = activeColor.value
  activeColor.value = disabledColor.value
  disabledColor.value = tempColor

  let index = 0
  const intervalId = setInterval(() => {
    pathColors.value[index]
      = pathColors.value[index] === activeColor.value
        ? disabledColor.value
        : activeColor.value
    index++
    if (index === pathColors.value.length)
      clearInterval(intervalId)
  }, 130)
}

onMounted(() => {
  setInterval(toggleColors, 800)
})

if (import.meta.dev) {
  if (props.error.stack.length > 0) {
    const stack = new DOMParser().parseFromString(props.error.stack, 'text/html')
    const body = stack.firstChild.lastChild.firstChild
    stackArr.value.push(body.textContent)
  }
}
</script>

<template>
  <NuxtLayout name="error">
    <div class="w-full min-h-screen flex flex-col items-center justify-center">
      <div class="md:w-2/3 w-full px-4">
        <DevOnly>
          <div
            class="flex flex-col gap-2 text-2xl font-bold text-white bg-[#383D44] p-5 my-12 rounded-md show"
          >
            <p>{{ props.error.message }}</p>
            <div class="flex gap-3" style="border-left: 1px rgba(255,255,255,0.3) solid;">
              <div />
              <div class="flex flex-col gap-1 font-mono text-sm">
                <p v-for="(string, idx) in stackArr" :key="idx">
                  {{ string }}
                </p>
              </div>
            </div>
            {{error}}
          </div>
        </DevOnly>
        <h1 class="text-h3 md:text-h2 lg:text-h1 mb-10">
          404<br>Эта страница где-то затерялась
        </h1>
        <div class="text-2xl mt-4 lg:mt-6">
          Возможно, она никогда и не существовала, а может быть мы её
          удалили.<br>Можете попробовать вернуться на
          <NuxtLink
            class="text-blue-500 cursor-pointer hover:border-b"
            @click="handleError"
          >
            главную страницу
          </NuxtLink>
          или почитать что-нибудь интересное в нашем блоге.
        </div>
        <div class="flex flex-row flex-nowrap mt-12">
          <svg
            v-for="(item, index) in pathColors"
            :key="index"
            width="194"
            viewBox="0 0 194 194"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M186.536 76.0438C186.192 74.979 185.556 74.0318 184.702 73.3094C183.847 72.587 182.808 72.1183 181.701 71.9563L126.803 63.978L102.256 14.2308C101.704 13.2947 100.917 12.5188 99.9733 11.9798C99.0297 11.4407 97.9617 11.1572 96.875 11.1572C95.7882 11.1572 94.7203 11.4407 93.7766 11.9798C92.833 12.5188 92.0463 13.2947 91.4943 14.2308L66.9468 63.9668L12.0494 71.9563C10.9418 72.1175 9.90138 72.5854 9.04578 73.3071C8.19018 74.0288 7.55354 74.9754 7.20784 76.04C6.86214 77.1046 6.82117 78.2446 7.08957 79.3313C7.35796 80.418 7.925 81.4079 8.7266 82.1891L48.4547 120.905L39.0821 175.594C38.8925 176.697 39.0153 177.831 39.4366 178.867C39.8579 179.903 40.5608 180.801 41.4659 181.459C42.371 182.116 43.4421 182.508 44.5579 182.588C45.6738 182.669 46.7899 182.435 47.78 181.914L96.875 156.09L145.976 181.903C146.965 182.423 148.08 182.656 149.194 182.576C150.309 182.496 151.379 182.106 152.283 181.449C153.188 180.793 153.891 179.897 154.314 178.863C154.736 177.828 154.861 176.696 154.674 175.594L145.301 120.916L185.029 82.2003C185.83 81.4175 186.396 80.4257 186.663 79.3375C186.929 78.2493 186.885 77.1082 186.536 76.0438Z"
              :fill="item"
            />
          </svg>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<style scoped lang="scss">
@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.show {
  animation: show 200ms ease-in-out;
}
</style>
