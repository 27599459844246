import type { RouterOptions } from 'nuxt/schema'

export default <RouterOptions>{
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition)
      return savedPosition

    const isSpecialNavigation = [from.path, to.path].every(
      path => path.includes('/rating'),
    ) && ![from.path, to.path].some(path => path.includes('/org'))

    if (from.path === '/blog' && to.path.startsWith('/blog/')) {
      return { top: 0, behavior: 'smooth' }
    }

    if (isSpecialNavigation)
      return savedPosition

    return { top: 0, behavior: 'smooth' }
  },
}
