type TAction = 'click' | 'submit'
type TCategory = 'Audit' | 'App' | 'Newsletter' | 'Rating' | 'Blog'

export default defineNuxtPlugin(() => {
  const log = useLogger('GTM')
  const { proxy: gtm } = useScriptGoogleTagManager()

  function pushData(event?: string, additionalOptions?: object) {
    const data = {
      event: event || 'intercept',
      ...additionalOptions,
    }

    gtm.dataLayer.push(data)
    log.info(data)
  }

  function sendAnalytics(
    event: string,
    action: TAction,
    category: TCategory,
    label?: string,
  ) {
    const data = {
      event,
      eventAction: action,
      eventCategory: category,
      eventLabel: label,
      pagePath: useRoute().path,
      timestamp: new Date().toISOString(),
    }

    gtm.dataLayer.push(data)
    log.info(data)
  }

  return {
    provide: {
      gtm: {
        sendAnalytics,
        pushData,
      },
    },
  }
})
